.ant-select-clear {
  font-size: 18px !important;
  top: 40% !important;
}
.ant-select-selection-search-input {
  /*caret-color: transparent !important;*/
}
/* .ant-select-arrow {
  display: none !important;
} */
.ant-tag {
  word-break: break-all;
}
